<template>
  <div class="card-row">
    <div class="card" @click="navigateToDetailsPage">
      <img
        src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner1.jpg"
        alt="Image"
        class="card-image"
      />
      <div class="card-content">
        <h2>How to Determine If a Short-Term Loan Is Right for You</h2>
        <p>
          Navigating the financial landscape can be daunting, especially when
          unexpected expenses arise. Lime24...
        </p>
        <router-link :to="{ name: 'FinancialTipsArticle1' }"
          >Read more</router-link
        >
      </div>
    </div>

    <!-- Tips for Responsible Borrowing: Making... -->
    <div class="card" @click="navigateToDetailsPage">
      <img
        src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner.jpg"
        alt="Image"
        class="card-image"
      />
      <div class="card-content">
        <h2>
          Tips for Responsible Borrowing: Making the Most of Your Short-Term
          Loan.
        </h2>
        <p>
          In South Africa, short-term loans provide immediate financial relief,
          but they come with significant responsibilities...
        </p>
        <router-link :to="{ name: 'FinancialTipsArticle2' }"
          >Read more</router-link
        >
      </div>
    </div>

    <!-- How Inflation Affects Your Everyday Life and Finances -->
    <div class="card" @click="navigateToDetailsPage">
      <img
        src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner8.jpg"
        alt="Image"
        class="card-image"
      />
      <div class="card-content">
        <h2>How Inflation Affects Your Everyday Life and Finances</h2>
        <p>
          Inflation is an economic phenomenon that everyone hears about, but not
          everyone fully understands...
        </p>
        <router-link :to="{ name: 'FinancialTipsArticle4' }"
          >Read more</router-link
        >
      </div>
    </div>

    <!-- Navigating the Rising Cost of Living in South Africa -->
    <div class="card" @click="navigateToDetailsPage">
      <img
        src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner9.jpg"
        alt="Image"
        class="card-image"
      />
      <div class="card-content">
        <h2>Navigating the Rising Cost of Living in South Africa</h2>
        <p>
          South Africa, like many countries worldwide, is grappling with a
          steadily rising cost of living...
        </p>
        <router-link :to="{ name: 'FinancialTipsArticle3' }"
          >Read more</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LimeAllArticle",
  data() {
    return {
      articles: [
        {
          img: "@/assets/img/public/pages/blog/Lime-24-Blog-Banner1.jpg",
          headingOne: "How to Determine If a Short-Term Loan Is Right for You",
          text: `Navigating the financial landscape can be daunting, especially when unexpected expenses arise. Lime24,`,
        },
        {
          img: "@/assets/img/public/pages/blog/Lime-24-Blog-Banner1.jpg",
          headingOne: "Understanding Short Term Loans: The Basics and Benefits",
          text: `In South Africa, a startling statistic revealed that in 2021, 76% of South Africans regularly ran,`,
        },
        {
          img: "@/assets/img/public/pages/blog/Lime-24-Blog-Banner1.jpg",
          headingOne:
            "Tips for Responsible Borrowing: Making the Most of Your Short-Term Loan.",
          text: `Navigating the financial landscape can be daunting, especially when unexpected expenses arise. Lime24,`,
        },
        {
          img: "@/assets/img/public/pages/blog/Lime-24-Blog-Banner1.jpg",
          headingOne: "How to Determine If a Short-Term Loan Is Right for You",
          text: `In South Africa, short-term loans provide immediate financial relief, but they come with significant responsibilities.`,
        },
      ],
    };
  },
  methods: {
    navigateToDetailsPage() {
      // You can navigate programmatically using router.push
      this.$router.push();
    },
  },
};
</script>

<style scoped>
.card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Adjust as needed */
}
@media only screen and (max-width: 768px) {
  .card-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95vw;
  }
  .card {
    width: 80% !important;
    height: 520px !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
}
.card {
  height: 500px;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s ease;
  margin-bottom: 2em;
  border-radius: 0;
  width: calc(50% - 10px);
  margin-bottom: 20px;
  font-size: 15px;
}

.card:hover {
  transform: scale(1.05);
}

.card-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.card-content {
  padding: 20px;
}
</style>
