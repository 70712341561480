<template>
  <div class="default-list-subheader">
    <v-list class="ml-n6 transparent">
      <v-list-item
        class="mt-n7"
        v-for="(listItem, index) in listItems"
        :key="index"
      >
        <v-list-item-icon class="mt-3">
          <v-icon color="primary">mdi-circle-medium</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="z-index ml-n7">
          <p class="b-body-1">
            <b>{{ listItem.text1 }}:</b> {{ listItem.text }}
          </p>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "LimeLoansDefaultlistWithSubHeader",

  props: {
    listItems: Array,
  },
};
</script>

<style lang="scss" scoped>
//
</style>
