import { render, staticRenderFns } from "./article12.vue?vue&type=template&id=7a86add7&scoped=true&"
import script from "./article12.vue?vue&type=script&lang=js&"
export * from "./article12.vue?vue&type=script&lang=js&"
import style0 from "./article12.vue?vue&type=style&index=0&id=7a86add7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a86add7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VContainer,VImg,VRow})
