<template>
  <div class="about-lime">
    <v-card color="white" width="100%" flat tile>
      <v-container>
        <v-row>
          <!-- About Lime -->
          <v-col cols="12" style="padding: 0%">
            <h4 class="heading-h4">
              Navigating Lime24's Easy Application Process: Your Gateway to
              Quick Financial Assistance
            </h4>
            <br />
            <v-img
              alt="debicheck"
              class="debicheck hidden-xs-only"
              contain
              src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner6.jpg"
              max-width="700"
            />
          </v-col>

          <v-col cols="12" lg="10">
            <!-- listItems1 -->
            <p
              v-for="(listItem, index) in listItems1"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>

            <!-- listItems2 -->
            <h6 class="heading-h4">Step 1: Start with an Online Application</h6>
            <br />
            <p
              v-for="(listItem, index) in listItems2"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>

            <h4 class="heading-h4 mt-4 mb-4">
              Step 2: Quick Submission of Personal Details
            </h4>

            <!-- listItems2 -->
            <p
              v-for="(listItem, index) in listItems3"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>

            <h4 class="heading-h4 mt-4 mb-4">
              Step 3: Receive a Quick Decision
            </h4>

            <p
              v-for="(listItem, index) in listItems4"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>
            <h4 class="heading-h4 mt-4 mb-4">Step 4: Fast Loan Payout</h4>

            <p
              v-for="(listItem, index) in listItems5"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>
            <h4 class="heading-h4 mt-4 mb-4">
              The Significance of a Simple and Fast Application Process
            </h4>

            <p
              v-for="(listItem, index) in listItems6"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LimeLoansArticle4",

  data() {
    return {
      listItems1: [
        {
          text: `In moments of unexpected financial need, it’s important to find a swift and straightforward 
                solution. <a href="https://lime24.co.za/"> Lime24</a> stands out in the financial services sector by offering a seamless, easy-to-understand application process for short-term loans. This approach not only aligns with the 
                immediate needs of those seeking financial relief but also ensures a stress-free experience from 
                start to finish. Below, we outline the uncomplicated steps to secure a short-term loan with 
                Lime24, highlighting the simplicity and speed of the process.`,
        },
      ],
      listItems2: [
        {
          text: `Embarking on your Lime24 loan journey begins with a visit to our website. The platform is 
meticulously designed to guide you effortlessly through selecting your loan amount and 
repayment terms. This initial step is crucial, as it sets the foundation for your loan agreement, 
tailored to suit your financial situation without overwhelming you with unnecessary complexity.`,
        },
      ],
      listItems3: [
        {
          text: `Following the selection of your loan specifics, you'll proceed to submit your personal 
information. Lime24 simplifies this process, requiring only essential details such as your 
identification, employment status, and financial background. This streamlined submission is 
pivotal for Lime24 to craft a loan offer that respects your financial capacity and repayment 
potential. Lime24 champions <a href="https://lime24.co.za/faqs">data security</a>, ensuring your information is handled with the utmost 
confidentiality.`,
        },
      ],

      listItems4: [
        {
          text: `Lime24's efficiency shines brightest at this stage, where their advanced algorithm assists loan 
            consultants with the loan verification process and allows for quick decision making on your loan 
            application. This prompt response eliminates the agonising wait associated with traditional loan 
            applications, offering peace of mind and rapid access to financial assistance when it's most 
            needed`,
        },
      ],

      listItems5: [
        {
          text: `Upon approval, the loan amount is swiftly transferred to your bank account, affirming Lime24's 
                commitment to providing immediate financial support. This quick payout is integral to addressing 
                your urgent financial needs, showcasing Lime24's efficient operational model.`,
        },
      ],
      listItems6: [
        {
          text: `The value of a user-friendly loan application process in today's fast-moving world cannot be 
            overstated. Lime24's approach to loan applications caters to the immediacy of modern financial 
            demands, ensuring individuals can access funds promptly and without unnecessary hassle. This 
            simplicity is especially crucial for those facing financial emergencies, where time is of the 
            essence.`,
        },
        {
          text: `Moreover, a straightforward application process demystifies the often complex world of finance, 
            making it accessible to a wider audience. Whether you're a young professional, a tech-savvy 
            individual, or someone in urgent need of financial assistance, Lime24's process is designed to 
            meet you where you are, offering a lifeline in times of need.`,
        },
        {
          text: `In conclusion, Lime24's short-term loan application process exemplifies ease and efficiency, 
            embodying a user-centric approach to financial services. From the initial online application to 
            the swift disbursement of funds, each step is designed with the borrower's convenience and 
            urgency in mind. By choosing Lime24, you gain more than just financial assistance; you 
            embrace a seamless service that prioritises your time and needs.`,
        },
        {
          text: `Visit <a href="https://lime24.co.za/">https://lime24.co.za/</a> today to experience the simplicity of securing the financial support you 
    require, precisely when you need it most.`,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.about-lime {
  .h6 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
}
</style>
