<template>
  <div class="about-lime">
    <v-card color="white" width="100%" flat tile>
      <v-container>
        <v-row>
          <!-- About Lime -->
          <!-- <v-col cols="12" style="padding: 0%"> -->

          <div>
            <h4 class="heading-h4">How to Avoid Over-indebtedness</h4>
            <br />
            <v-img
              alt="debicheck"
              class="debicheck hidden-xs-only"
              src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner12.png"
              max-height="500"
            />
            <!-- </v-col> -->

            <!-- <v-col cols="12" lg="10"> -->
            <!-- listItems1 -->
            <p class="b-body-1 mt-5">
              Running into money problems has long since become the least bit
              uncommon. In fact, most people are confronted by running out of
              money at certain times. <br /><br />

              But, being left with more month at the end of your money, isn’t a
              default indicator that you’re over-indebted. Fortunately – unlike
              over-indebtedness - such sporadic shortfalls can usually be
              remedied swiftly and with relative ease.<br /><br />

              Over-indebtedness, however, is more serious, often very complex,
              and ultimately, much tougher to resolve. In short, it constitutes
              a complete, or near complete, inability to service debts, cover
              regular monthly expenses, as well as any day-to-day demands.<br /><br />

              Over-indebtedness is pervasive and persistent. It develops and
              worsens over time. It’s often set into motion by certain
              unexpected life events, such as retrenchment, divorce, illness or
              death in the family. <br /><br />

              It’s important to note that this beast can very simply, also be
              unleashed by overspending. Unfortunately, anyone can quite easily,
              and rather quickly get caught in the downward spiral of
              over-indebtedness. <br /><br />

              So, you get to decide . . . Deny and Drown OR Accept and
              Accelerate
            </p>
            <!-- </v-col> -->
          </div>
          <div>
            <h4 class="heading-h4 my-4">Accept and Accelerate:</h4>

            <!-- 1. Plan ahead... -->
            <h4 class="ml-md-5">1. Plan ahead:</h4>
            <p class="b-body-1 ml-md-9">{{ listItems2 }}</p>

            <!-- 2. Get Credit Insurance:... -->
            <h4 class="ml-md-5">2. Get Credit Insurance:</h4>
            <p class="b-body-1 ml-md-9">{{ listItems3 }}</p>

            <!-- 3. Actively Build Your Savings: ... -->
            <h4 class="ml-md-5">3. Actively Build Your Savings:</h4>
            <p class="b-body-1 ml-md-9">{{ listItems4 }}</p>

            <!-- 4. Communicate with... -->
            <h4 class="ml-md-5">4. Communicate with Creditors:</h4>
            <p class="b-body-1 ml-md-9">{{ listItems5 }}</p>

            <!-- 5. Maintain a Realistic and Well-Balanced Budget: ... -->
            <h4 class="ml-md-5">
              5. Maintain a Realistic and Well-Balanced Budget:
            </h4>
            <p class="b-body-1 ml-md-9">{{ listItems6 }}</p>

            <h4 class="heading-h4 mb-2 mt-md-7">
              Unless you decide to Deny and Drown, this is what you need to
              avoid:
            </h4>

            <!-- 1. Avoid Late Payments ... -->
            <h4 class="mt-2 ml-md-5">1. Avoid Late Payments:</h4>
            <p class="b-body-1 ml-md-9">{{ listItems7 }}</p>

            <!-- 1. Don’t Take Out New Loans: -->
            <h4 class="ml-md-5">2. Don’t Take Out New Loans:</h4>
            <p class="b-body-1 ml-md-9">{{ listItems8 }}</p>

            <!-- 3. Stay Away from Revolving Credit:  -->
            <h4 class="ml-md-5">3. Stay Away from Revolving Credit:</h4>
            <p class="b-body-1 ml-md-9">{{ listItems9 }}</p>

            <!-- 4. Avoid Overdraft Facilities:   -->
            <h4 class="ml-md-5">4. Avoid Overdraft Facilities:</h4>
            <p class="b-body-1 ml-md-9">{{ listItems10 }}</p>

            <!-- 5. Think Twice About Loan Consolidation:  -->
            <h4 class="ml-md-5">5. Think Twice About Loan Consolidation:</h4>
            <p class="b-body-1 ml-md-9">{{ listItems11 }}</p>

            <!--Let’s distil: -->
            <h4 class="mt-5">Let’s distil:</h4>
            <p class="b-body-1 mt-2">{{ listItems12 }}</p>

            <!--In conclusion: -->
            <h4 class="heading-h4 mt-4">In conclusion:</h4>
            <p class="b-body-1 mt-2">
              Your very first money-smart decision can only then be to
              Acknowledge and Accelerate.
            </p>
          </div>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import DefaultListWithHeader from "@/components/shared/lists/DefaultListWithSubHeader";

export default {
  name: "LimeLoansArticle9",

  components: {
    DefaultListWithHeader,
  },

  data() {
    return {
      listItems1: `The short-term lending industry in South Africa is poised for significant change as
          technological advancements, regulatory shifts, and evolving consumer behaviors reshape the
          financial landscape. These developments will have far-reaching implications for consumers,
          making it crucial for individuals to stay informed about the latest trends in short-term lending.
          In this article, we'll explore the future of short-term lending in South Africa's economy,
          the trends likely to emerge, and the potential benefits for consumers. We'll also look at
          how platforms like Lime24 are at the forefront of this evolving industry.`,

      listItems2: `Think of potential financial difficulties you might face and prepare accordingly. 
      This might involve setting up an emergency fund or learning to budget more effectively.`,

      listItems3: `If you're taking out a mortgage, consider credit insurance. It acts as a safety 
      net in case you face unexpected financial disruptions. It might feel like an extra expense now, 
      but it could save you a lot of stress later.`,

      listItems4: `Try to set aside some money each month. This can be your buffer for those incidental 
      and unexpected expenses. Even small amounts add up over time and provide a cushion.`,

      listItems5: `Should you find yourself struggling to keep up with payments, reach out to your 
      creditors immediately. They might be able to offer extended repayment terms or reschedule your 
      loans. It’s always better to be upfront, rather than avoiding their calls.`,

      listItems6fff: [
        {
          text: `With digital platforms like Lime24 simplifying the loan application process, more individuals, including 
          those traditionally underserved by banks, will have access to short-term loans. This increased access can help 
          bridge financial gaps in times of need.`,
          text1: `Increased Access to Credit`,
        },

        {
          text: `As competition among lenders intensifies, consumers can expect better loan products with more favorable 
          terms, such as lower interest rates and more flexible repayment options.`,
          text1: `Better Financial Products`,
        },
        {
          text: `The emphasis on consumer education will lead to a more financially savvy population that understands how 
          to use credit responsibly, thereby reducing the risk of falling into debt traps`,
          text1: `Improved Financial Literacy:`,
        },
      ],

      listItems6: `If things get tight, consider selling some of your valuables. But do this only after 
      thinking it through very cautiously, and only when necessary. It’s better to part with things you 
      don’t absolutely need than to sink deeper into debt whilst hanging on to them.`,

      listItems7: `Late fees add up quickly and can make your debt situation worse. Set reminders or 
      automate payments if you can. `,

      listItems8: `It might seem like a quick fix, but new loans can exacerbate your financial problems. 
      Try to work with what you have.`,

      listItems9: `Credit cards and other forms of revolving credit can be tempting, but their 
            high interest rates can hold one hostage in a never-ending cycle of debt. `,

      listItems10: `Overdrafts might seem like a convenient option, but they carry high fees and often 
      require immediate repayment. It’s easy to fall into a cycle of dependence on these. `,

      listItems11: `Consolidating loans can sometimes seem like a good idea, but it often means you’re 
      extending the repayment period, which can increase the total amount of debt you’ll have to repay. 
      Consider all the implications carefully before deciding on this option. `,

      listItems12: `Managing debt responsibly is largely about keeping control over your borrowing 
            habits, spending habits, as well as your budget. It’s key to address financial challenges 
            head-on. Avoid relying solely on borrowing to get through those tough times. Be proactive 
            about managing your finances to prevent small issues from turning into huge problems. The 
            reality of things like unexpected events and rising debt costs will always be lurking, 
            but with careful planning and smart decisions, you can trump these. `,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.about-lime {
  .h6 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
}
</style>
