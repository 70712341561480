<template>
  <div>
    <v-row>
      <!-- Emergency Situations and the Role of Short-Term Loans in South Africa -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Picture-Article.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              Emergency Situations and the Role of Short-Term Loans in South
              Africa
            </h3>
            <p>
              Emergency situations often come without warning, leaving
              individuals and families in South Africa facing...
            </p>
            <router-link :to="{ name: 'HelpfulInformation' }">
              Read more</router-link
            >
          </div>
        </div>
      </v-col>

      <!-- How to Determine If a Short-Term Loan Is Right for You -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner1.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              How to Determine If a Short-Term Loan Is Right for You
            </h3>
            <p>
              Navigating the financial landscape can be daunting, especially
              when unexpected expenses arise. Lime24...
            </p>
            <router-link :to="{ name: 'FinancialTips' }">
              Read more</router-link
            >
          </div>
        </div>
      </v-col>

      <!--  Understanding Short Term Loans: The Basics and Benefits -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner2.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              Understanding Short Term Loans: The Basics and Benefits
            </h3>
            <p>
              In South Africa, a startling statistic revealed that in 2021, 76%
              of South Africans regularly ran out of money...
            </p>
            <router-link :to="{ name: 'HelpfulInformation' }">
              Read more</router-link
            >
          </div>
        </div>
      </v-col>

      <!-- Tips for Responsible Borrowing: Making the Most of Your Short-Term Loan. -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              Tips for Responsible Borrowing: Making the Most of Your Short-Term
              Loan.
            </h3>
            <p>
              In South Africa, short-term loans provide immediate financial
              relief, but they come with significant responsibilities...
            </p>
            <router-link :to="{ name: 'FinancialTips' }">
              Read more</router-link
            >
          </div>
        </div>
      </v-col>

      <!-- Why Choose Lime24 for Your Short-Term Loans in South Africa -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner5.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="card-content">
            <h3 style="font-weight: 800; font-size: 20px">
              Why Choose Lime24 for Your Short-Term Loans in South Africa
            </h3>
            <p>
              In the complex landscape of financial solutions, short-term loans
              have emerged as a beacon of
            </p>
            <router-link :to="{ name: 'CompanyNews' }"> Read more</router-link>
          </div>
        </div>
      </v-col>

      <!-- Navigating Lime24's Easy Application Process. -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner6.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              Navigating Lime24's Easy Application Process.
            </h3>
            <p>
              In moments of unexpected financial need, it’s important to find a
              swift and straightforward solution...
            </p>
            <router-link :to="{ name: 'CompanyNews' }"> Read more</router-link>
          </div>
        </div>
      </v-col>

      <!-- Stronger Together – The Heartbeat of Success -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner7.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              Stronger Together – The Heartbeat of Success
            </h3>
            <p>
              In the fast-paced “business machine,” it's easy to get caught up
              in the pursuit of individual success...
            </p>
            <router-link :to="{ name: 'CompanyNews' }"> Read more</router-link>
          </div>
        </div>
      </v-col>

      <!-- How Inflation Affects Your Everyday Life and Finances -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner8.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              How Inflation Affects Your Everyday Life and Finances
            </h3>
            <p>
              Inflation is an economic phenomenon that everyone hears about, but
              not everyone fully understands...
            </p>
            <router-link :to="{ name: 'FinancialTips' }">
              Read more</router-link
            >
          </div>
        </div>
      </v-col>

      <!-- Navigating the Rising Cost of Living in South Africa  -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner9.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              Navigating the Rising Cost of Living in South Africa
            </h3>
            <p>
              South Africa, like many countries worldwide, is grappling with a
              steadily rising cost of living...
            </p>
            <router-link :to="{ name: 'FinancialTips' }">
              Read more</router-link
            >
          </div>
        </div>
      </v-col>

      <!-- The Future of Short-Term Lending in South Africa's Economy -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner10.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              The Future of Short-Term Lending in South Africa's Economy
            </h3>
            <p>
              The short-term lending industry in South Africa is poised for
              significant change as technological...
            </p>
            <router-link :to="{ name: 'HelpfulInformation' }">
              Read more</router-link
            >
          </div>
        </div>
      </v-col>

      <!-- Understanding South Africa’s Current Economic Climate -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner11.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              Understanding South Africa’s Current Economic Climate
            </h3>
            <p>
              South Africa, a nation rich in natural resources and cultural
              diversity, is currently navigating a complex...
            </p>
            <router-link :to="{ name: 'HelpfulInformation' }">
              Read more</router-link
            >
          </div>
        </div>
      </v-col>

      <!-- How to Avoid Over-indebtedness   -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner12.png"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              How to Avoid Over-indebtedness
            </h3>
            <p>
              Running into money problems has long since become the least bit
              uncommon. In fact, most people are...
            </p>
            <router-link :to="{ name: 'HelpfulInformation' }">
              Read more</router-link
            >
          </div>
        </div>
      </v-col>

      <!-- Navigating the Rising Cost of Living in South Africa -->
      <v-col cols="12" md="6">
        <div class="card" @click="navigateToDetailsPage">
          <img
            src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner13.jpg"
            alt="Image"
            class="card-image"
          />
          <div class="pa-4 pt-3">
            <h3 style="font-weight: 800; font-size: 20px">
              The Role of Short-Term Loans in Supporting Small Businesses
            </h3>
            <p>
              To survive financially in today's financial climate, many salary
              earners start small businesses to supplement...
            </p>
            <router-link :to="{ name: 'HelpfulInformation' }">
              Read more</router-link
            >
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- <div class="card" @click="navigateToDetailsPage">
      <img
        src="@/assets/img/public/pages/blog/Picture-Article.jpg"
        alt="Image"
        class="card-image"
      />
      <div class="card-content">
        <h2>
          Emergency Situations and the Role of Short-Term Loans in South Africa
        </h2>
        <p>
          Emergency situations often come without warning, leaving individuals
          and families in South Africa facing....
        </p>
        <router-link :to="{ name: 'HelpfulInformation' }">
          Read more</router-link
        >
      </div>
    </div>

    <div class="card" @click="navigateToDetailsPage">
      <img
        src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner1.jpg"
        alt="Image"
        class="card-image"
      />
      <div class="card-content">
        <h2>How to Determine If a Short-Term Loan Is Right for You</h2>
        <p>
          Navigating the financial landscape can be daunting, especially when
          unexpected expenses arise. Lime24...
        </p>
        <router-link :to="{ name: 'FinancialTips' }"> Read more</router-link>
      </div>
    </div>

    <div class="card" @click="navigateToDetailsPage">
      <img
        src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner2.jpg"
        alt="Image"
        class="card-image"
      />
      <div class="card-content">
        <h2>Understanding Short Term Loans: The Basics and Benefits</h2>
        <p>
          In South Africa, a startling statistic revealed that in 2021, 76% of
          South Africans regularly ran out of money....
        </p>
        <router-link :to="{ name: 'HelpfulInformation' }">
          Read more</router-link
        >
      </div>
    </div>

    <div class="card" @click="navigateToDetailsPage">
      <img
        src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner.jpg"
        alt="Image"
        class="card-image"
      />
      <div class="card-content">
        <h2>
          Tips for Responsible Borrowing: Making the Most of Your Short-Term
          Loan.
        </h2>
        <p>
          In South Africa, short-term loans provide immediate financial relief,
          but they come with significant responsibilities.....
        </p>
        <router-link :to="{ name: 'FinancialTips' }"> Read more</router-link>
      </div>
    </div>

    <div class="card" @click="navigateToDetailsPage">
      <img
        src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner5.jpg"
        alt="Image"
        class="card-image"
      />
      <div class="card-content">
        <h2>Why Choose Lime24 for Your Short-Term Loans in South Africa</h2>
        <p>
          In the complex landscape of financial solutions, short-term loans have
          emerged as a beacon of
        </p>
        <router-link :to="{ name: 'CompanyNews' }"> Read more</router-link>
      </div>
    </div>

    <div class="card" @click="navigateToDetailsPage">
      <img
        src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner6.jpg"
        alt="Image"
        class="card-image"
      />
      <div class="card-content">
        <h2>Navigating Lime24's Easy Application Process.</h2>
        <p>
          In moments of unexpected financial need, it’s important to find a
          swift and straightforward solution...
        </p>
        <router-link :to="{ name: 'CompanyNews' }"> Read more</router-link>
      </div>
    </div>

    <div class="card" @click="navigateToDetailsPage">
      <img
        src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner7.jpg"
        alt="Image"
        class="card-image"
      />
      <div class="card-content">
        <h2>Stronger Together – The Heartbeat of Success</h2>
        <p>
          In the fast-paced “business machine,” it's easy to get caught up in
          the pursuit of individual success.......
        </p>
        <router-link :to="{ name: 'CompanyNews' }"> Read more</router-link>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "LimeAllArticle",
  data() {
    return {
      articles: [
        {
          img: "@/assets/img/public/pages/blog/Lime-24-Blog-Banner1.jpg",
          headingOne: "How to Determine If a Short-Term Loan Is Right for You",
          text: `Navigating the financial landscape can be daunting, especially when unexpected expenses arise. Lime24,`,
        },
        {
          img: "@/assets/img/public/pages/blog/Lime-24-Blog-Banner1.jpg",
          headingOne: "Understanding Short Term Loans: The Basics and Benefits",
          text: `In South Africa, a startling statistic revealed that in 2021, 76% of South Africans regularly ran,`,
        },
        {
          img: "@/assets/img/public/pages/blog/Lime-24-Blog-Banner1.jpg",
          headingOne:
            "Tips for Responsible Borrowing: Making the Most of Your Short-Term Loan.",
          text: `Navigating the financial landscape can be daunting, especially when unexpected expenses arise. Lime24,`,
        },
        {
          img: "@/assets/img/public/pages/blog/Lime-24-Blog-Banner1.jpg",
          headingOne: "How to Determine If a Short-Term Loan Is Right for You",
          text: `In South Africa, short-term loans provide immediate financial relief, but they come with significant responsibilities.`,
        },
      ],
    };
  },
  methods: {
    navigateToDetailsPage() {
      // You can navigate programmatically using router.push
      this.$router.push();
    },
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  height: 440px;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s ease;
  margin-bottom: 2em;
  border-radius: 0;
}

@media only screen and (max-width: 768px) {
  .card {
    height: 680px !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
}

.card:hover {
  transform: scale(1.05);
}

.card-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.card-content {
  padding: 20px;
}
</style>
