<template>
  <div class="allnews-page">
    <Article />
  </div>
</template>

<script>
import Article from "@/components/public/pages/blog/helpful-infor-articles/article10";

export default {
  name: "HelpfullInformationsArticle10",

  // eslint-disable-next-line vue/no-unused-components
  components: { Article },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
//
</style>
