<template>
  <div class="about-lime">
    <v-card color="white" width="100%" flat tile>
      <v-container>
        <v-row>
          <!-- About Lime -->
          <v-col cols="12" style="padding: 0%">
            <h4 class="heading-h4">
              Stronger Together – The Heartbeat of Success
            </h4>
            <br />
            <v-img
              alt="debicheck"
              class="debicheck hidden-xs-only"
              contain
              src="@/assets/img/public/pages/blog/Lime-24-Blog-Banner7.jpg"
              max-width="700"
            />
          </v-col>

          <v-col cols="12" lg="10">
            <!-- listItems1 -->
            <p
              v-for="(listItem, index) in listItems1"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LimeLoansArticle4",

  data() {
    return {
      listItems1: [
        {
          text: `In the fast-paced “business machine,” it's easy to get caught up in the pursuit of individual 
                success. But what if we shifted our focus from "me" to "we"? What if we embraced the 
                power of the team to realize that together, we are stronger?`,
        },
        {
          text: `Commitment and belief should pave the way. Like a rugby team on the field, each member 
                of an office or company must share and chase after common goals. Be it mission or vision, it 
                can only be achieved through cohesion. When everyone is aligned with shared purpose . . . 
                that’s when the magic happens.`,
        },
        {
          text: `When teammates hold each other high by providing unwavering support – even in the face
                    of adversity – that’s when the strength of teamwork shines brightest.`,
        },
        {
          text: `Victories are not solitary achievements; they are collective successes which must also be 
                celebrated together. Triumph strengthens camaraderie forged through teamwork.`,
        },
        {
          text: `However, it’s not winning alone that defines a team. Setbacks and failures – however
                painful – often present opportunities to raise the bar higher still. In times like these, it’s 
                through collective learning and reflection that teams are empowered to rise above 
                adversity. Each setback an opportunity for growth, shaping the team into a stronger force, 
                ready to overcome any obstacle.`,
        },
        {
          text: `Teamwork ignites passion. Each member bears witness to creating the synergy, which is 
                vital to success. Collectively propelled towards excellence`,
        },
        {
          text: `Embrace its power with open arms and open minds. Let ‘s create something extraordinary 
                together. For in collaboration lies the potential to achieve greatness beyond measure.`,
        },
        {
          text: `But of course, teamwork doesn't suddenly appear out of thin air. It takes commitment, 
                determination, and drive. It also asks for good old hard work, and the continuous 
                development of skills.`,
        },
        {
          text: `Supporting one another is a crucial component, and so is showing compassion along the 
                way. And, boosting others, create the perfect climate for everyone to thrive`,
        },
        {
          text: `Trust is key, trust in our own and our colleagues' abilities. Trust begets the bravery needed
            to take risks and push boundaries . . . through bravery comes victory.`,
        },
        {
          text: `Embrace the power of the team, together with “the power of now” – make the present a 
                reality where collaboration abounds, and greatness is achieved. Let’s make every second 
                count. Work hand in hand towards the mutual goals.`,
        },
        {
          text: `Winning isn't achieved through talent alone; it's about how well teams work together as a 
                unit. Come together with confidence, tenacity, hard work, and compassion – for when you 
                unite as one unstoppable force, victory not only becomes possible, but it also becomes 
                inevitable.`,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.about-lime {
  .h6 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
}
</style>
