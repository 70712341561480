<template>
  <div class="about-lime">
    <v-card color="white" width="100%" flat tile>
      <v-container>
        <v-row>
          <!-- About Lime -->
          <v-col cols="12" style="padding: 0%">
            <h4 class="heading-h4">
              Emergency Situations and the Role of Short-Term Loans in South
              Africa
            </h4>
            <br />
            <v-img
              alt="debicheck"
              class="debicheck hidden-xs-only"
              contain
              src="@/assets/img/public/pages/blog/Picture-Article.jpg"
              max-width="700"
            />
          </v-col>

          <v-col cols="12" lg="10">
            <!-- listItems1 -->
            <p
              v-for="(listItem, index) in listItems1"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>

            <DefaultListWithHeader :listItems="listItems5" />

            <h4 class="heading-h4 mt-4 mb-4">
              The Role of Short-Term Loans in Financial Planning
            </h4>

            <!-- listItems2 -->
            <p
              v-for="(listItem, index) in listItems2"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>

            <h4 class="heading-h4 mt-4 mb-4">Responsible Borrowing</h4>

            <p
              v-for="(listItem, index) in listItems3"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>

            <h4 class="heading-h4 mt-4 mb-4">Conclusion</h4>

            <p
              v-for="(listItem, index) in listItems4"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import DefaultList from "@/components/shared/lists/DefaultList";
import DefaultListWithHeader from "@/components/shared/lists/DefaultListWithSubHeader";

export default {
  name: "LimeLoansArticle",

  components: {
    // eslint-disable-next-line vue/no-unused-components
    DefaultList,
    DefaultListWithHeader,
  },

  data() {
    return {
      listItems1: [
        {
          text: `Emergency situations often come without warning, leaving individuals and families in
            South Africa facing immediate financial pressures. Whether it's an unexpected
            medical bill, urgent car repairs, or a sudden need to travel, the financial strain can be
            overwhelming. In these critical moments, short-term loans can serve as a lifeline,
            providing quick access to funds when time and speed are of the essence. In this
            context, services like Lime24 play a crucial role.`,
        },
        {
          text: `The Importance of Quick Financial Solutions in Emergencies.`,
        },
        {
          text: `In an emergency, the luxury of time is rarely on one's side. Traditional lending
            institutions, with their lengthy application processes and approval times, are not
            designed for urgent situations. This is where short-term loan providers like Lime24
            step in. We offer a swift and straightforward application process that can be
            completed online, ensuring that funds are disbursed as quickly as possible.`,
        },
        {
          text: `Lime24: A Solution for Urgent Financial Needs.`,
        },
        {
          text: `Online lenders like Lime24 have revolutionised this space with their swift and
            straightforward services. The importance of such platforms lies in their ability to
            disburse funds promptly, often within the same day, which can be pivotal during
            crises.`,
        },
        {
          text: `Lime24 offers competitive advantages that make it a go-to option in emergencies.
            The entire application process is paperless, and the transparency of the service
            ensures that there are no hidden fees, with all loan calculations clearly visible online .
            Flexibility is another significant benefit, as customers can choose their loan amount
            and term, tailoring the loan to their specific and immediate needs .`,
        },
        {
          text: `The company also incentivises timely repayment by offering higher loan values and
            longer repayment terms.`,
        },
        {
          text: `Here's why making use of Lime24 is important when in a financial emergency:`,
        },
      ],
      listItems2: [
        {
          text: `While short-term loans are invaluable in emergencies, they should be considered as
            part of a broader financial strategy. They are best used when the benefits of
            addressing an immediate need outweigh the costs of borrowing. It's also crucial to
            consider the repayment terms and ensure they align with your financial capacity.`,
        },
      ],
      listItems3: [
        {
          text: `It's important to borrow responsibly, even in an emergency. This means considering
            the necessity of the loan, the ability to repay it, and the terms and conditions of the
            lender. Lime24, like any financial service provider, requires careful consideration to
            ensure that it is the right choice for the borrower's specific situation`,
        },
      ],
      listItems4: [
        {
          text: `In the unpredictable landscape of life's emergencies, short-term loans can provide a
            necessary buffer. Services like Lime24 offer a practical solution for South Africans in
            need of quick financial assistance. By choosing a reliable and efficient short-term
            loan provider, individuals can navigate through their emergencies with one less
            worry.`,
        },
      ],
      listItems5: [
        {
          text: `Lime24's online application process is fast. In emergency situations, this
            speed can make all the difference, allowing individuals to address their urgent
            financial needs without delay.`,
          text1: `Speed`,
        },
        {
          text: `With an online platform, Lime24 is accessible to anyone with an
            internet connection, making it possible to apply for a loan outside of traditional
            banking hours, which is crucial in emergencies that occur at any time of day or night.`,
          text1: `Accessibility`,
        },
        {
          text: `Short-term loans often come with flexible repayment terms. Lime24 likely
            offers various options to ensure that borrowers can find a repayment schedule that
            suits their circumstances.`,
          text1: `Flexibility`,
        },
        {
          text: `Reputable short-term loan providers are upfront about their fees and
            interest rates. Users can typically calculate the total cost of their loan before
            committing, allowing for informed financial decisions even in pressing times.`,
          text1: `Transparency`,
        },
        {
          text: `Good customer service is essential, especially when dealing with financial
            stress. Platforms like Lime24 provide support to guide borrowers through the loan
            process, offering peace of mind when it's needed most.`,
          text1: `Support`,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.about-lime {
  .h6 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
}
</style>
